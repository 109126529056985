import config from 'config'
import * as t from './types'
import * as at from './const'
import { Action } from './actions'
import { defaultFilterValues } from './utils/filter-values-utils'

type ListState = {
  isFetching: boolean
  fetchError: string | null
  data: t.Product[] | null
  filterValues: t.FilterValues
  resetFilterValues: t.FilterValues
  filterOptions: {
    brand: t.FilterOption[]
    size: t.FilterOption[]
    color: t.FilterOption[]
    shop: t.FilterOption[]
    price: [number, number] | null
    category: t.CategoryOption[]
    wunderCategories: t.FilterOption[]
    index: string[]
    sorting: string
    dynamicFilters: Record<string, string[]>
  }
  dynamicFilters: t.DynamicFilterDefinition[]
  page: number
  exhaustiveHits: boolean
  exhaustiveFilters: boolean
  numPages: number
  numHits: number
  queryID: string
  mode: t.FetchMode
}

export type State = Record<t.Identifier, ListState>

export const initialListState: ListState = {
  isFetching: false,
  fetchError: null,
  data: null,
  filterValues: defaultFilterValues,
  resetFilterValues: defaultFilterValues,
  filterOptions: {
    brand: [],
    size: [],
    color: [],
    shop: [],
    price: null,
    category: [],
    wunderCategories: [],
    index: [config.indexes.products, config.indexes.products_price_asc],
    sorting: config.sortingAttributes.default,
    dynamicFilters: {},
  },
  dynamicFilters: [],
  page: 0,
  exhaustiveHits: true,
  exhaustiveFilters: true,
  numPages: 1,
  numHits: 1,
  queryID: '',
  mode: 'PENDING',
}

export default function reducer(state: State = {}, action: Action): State {
  if (!action.meta || !action.meta.identifier) return state
  const { identifier } = action.meta
  return {
    ...state,
    [identifier]: listReducer(state[identifier], action),
  }
}

function listReducer(state: ListState = initialListState, action: Action): ListState {
  switch (action.type) {
    case at.INIT_LIST:
      return {
        ...state,
        filterValues: action.payload,
        resetFilterValues: action.meta.resetValues,
        dynamicFilters: action.meta.dynamicFilters || initialListState.dynamicFilters,
        mode: 'PENDING',
      }

    case at.CLEAR_FILTER_VALUES: {
      if (!action.payload) return { ...state, filterValues: state.resetFilterValues }
      else if (state.resetFilterValues[action.payload]) {
        return {
          ...state,
          filterValues: {
            ...state.filterValues,
            [action.payload]: state.resetFilterValues[action.payload],
          },
        }
      } else if (state.resetFilterValues.dynamicFilters[action.payload]) {
        return {
          ...state,
          filterValues: {
            ...state.filterValues,
            dynamicFilters: {
              ...state.filterValues.dynamicFilters,
              [action.payload]: state.resetFilterValues.dynamicFilters[action.payload],
            },
          },
        }
      } else return state
    }

    case at.FETCH_REQUEST:
      return { ...state, isFetching: true, fetchError: null }

    case at.FETCH_FAILURE:
      return { ...state, isFetching: false, fetchError: action.payload }

    case at.FETCH_SUCCESS: {
      const priceSet = Boolean(state.filterValues.price)
      const priceMin = (() => {
        if (!state.filterValues.price) return 0
        return state.filterValues.price[0]
      })()
      const priceMax = (() => {
        if (!state.filterValues.price) return 0
        return state.filterValues.price[1]
      })()
      return {
        ...state,
        isFetching: false,
        data: action.payload.hits,
        numPages: action.payload.numPages,
        numHits: action.payload.numHits,
        page: action.payload.page,
        mode: action.payload.mode,
        queryID: action.payload.queryID,
        exhaustiveFilters: action.payload.exhaustiveFacetsCount,
        exhaustiveHits: action.payload.exhaustiveNbHits,
        filterValues: {
          ...state.filterValues,
          price: priceSet ? [priceMin, priceMax] : null,
        },
        filterOptions: {
          ...state.filterOptions,
          category: action.payload.categories,
          brand: action.payload.brandOptions,
          color: action.payload.colorOptions,
          shop: action.payload.shopOptions,
          size: action.payload.sizeOptions,
          wunderCategories: action.payload.wunderCategoryOptions,
          dynamicFilters: action.payload.dynamicFilterOptions,
          price: [action.payload.minPrice, action.payload.maxPrice],
        },
      }
    }

    case at.SET_QUERY: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          page: 0,
          query: action.payload,
        },
      }
    }

    case at.TOGGLE_CATEGORY: {
      return {
        ...state,
        mode: 'PENDING',
        filterValues: {
          ...state.filterValues,
          category: action.payload ? action.payload.categoryPath : '',
          page: 0,
        },
      }
    }

    case at.SET_PAGE: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          page: action.payload,
        },
      }
    }

    case at.TOGGLE_FILTER_OPTION: {
      const { filterKey } = action.meta
      if (state.filterValues[filterKey]) {
        const value = state.filterValues[filterKey]
        return {
          ...state,
          filterValues: {
            ...state.filterValues,
            page: 0,
            [filterKey]: value.includes(action.payload)
              ? value.filter((s) => s !== action.payload)
              : [...value, action.payload],
          },
        }
      } else {
        const value = state.filterValues.dynamicFilters[filterKey] || []
        return {
          ...state,
          filterValues: {
            ...state.filterValues,
            page: 0,
            dynamicFilters: {
              ...state.filterValues.dynamicFilters,
              [filterKey]: value.includes(action.payload)
                ? value.filter((s) => s !== action.payload)
                : [...value, action.payload],
            },
          },
        }
      }
    }

    case at.SET_CONTEXT: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          context: action.payload,
        },
      }
    }

    case at.SET_ANALYTIC_TAGS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          analyticTags: action.payload,
        },
      }
    }

    case at.SET_PRICE_RANGE: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          page: 0,
          price: action.payload,
        },
      }
    }

    case at.SET_INDEX: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          page: 0,
          index: action.payload,
        },
      }
    }

    case at.TOGGLE_TAG: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          page: 0,
          tags: state.filterValues.tags.includes(action.payload)
            ? state.filterValues.tags.filter((tag) => tag !== action.payload)
            : [...state.filterValues.tags, action.payload],
        },
      }
    }

    case at.SET_SORTING: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          page: 1,
          sorting: action.payload
        },
      }
    }

    default:
      return state
  }
}

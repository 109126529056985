// @flow
import * as t from './types'
import { State } from './reducer'
import { initialListState } from './reducer'
import createReSelector from 're-reselect'
import * as filterValuesUtils from './utils/filter-values-utils'

const emptyArray: any = []

// Request

export const filtersAreExhaustive = (state: State, identifier: t.Identifier): boolean =>
  state[identifier] ? state[identifier].exhaustiveFilters : true

export const isFetching = (state: State, identifier: t.Identifier): boolean =>
  state[identifier] ? state[identifier].isFetching : false

export const getFetchError = (state: State, identifier: t.Identifier): null | string =>
  state[identifier] ? state[identifier].fetchError : null

export const getData = (state: State, identifier: t.Identifier): null | t.Product[] =>
  state[identifier] ? state[identifier].data : null

export const getRequest: (
  state: State,
  identifier: t.Identifier
) => {
  isFetching: boolean
  fetchError: null | string
  data: t.Product[] | null
} = createReSelector(isFetching, getFetchError, getData, (isFetching, fetchError, data) => ({
  isFetching,
  fetchError,
  data,
}))((_, id) => `${id}`)

// FilterValue

export const getFilterValues = (state: State, identifier: t.Identifier): t.FilterValues =>
  state[identifier] ? state[identifier].filterValues : initialListState.filterValues

export const getResetFilterValues = (state: State, identifier: t.Identifier): t.FilterValues =>
  state[identifier] ? state[identifier].resetFilterValues : initialListState.resetFilterValues

export const getQuery = (state: State, identifier: t.Identifier): string =>
  state[identifier] ? state[identifier].filterValues.query : ''

export const getCategoryOptions = (state: State, identifier: t.Identifier): t.CategoryOption[] =>
  state[identifier] ? state[identifier].filterOptions.category : emptyArray

export const getPage = (state: State, identifier: t.Identifier): number =>
  state[identifier] ? state[identifier].filterValues.page : 0

export const getCategory = (state: State, identifier: t.Identifier): string =>
  state[identifier] ? state[identifier].filterValues.category : ''

export const getNumPages = (state: State, identifier: t.Identifier): number =>
  state[identifier] ? state[identifier].numPages : 0

export const getMode = (state: State, identifier: t.Identifier): t.FetchMode =>
  state[identifier] ? state[identifier].mode : 'PENDING'

export const getIndex = (state: State, identifier: t.Identifier): string =>
  state[identifier] ? state[identifier].filterValues.index : ''

export const getPrice = (state: State, identifier: t.Identifier): [number, number] | null =>
  state[identifier] ? state[identifier].filterValues.price : null

export const getIndexOptions = (state: State, identifier: t.Identifier): string[] =>
  state[identifier] ? state[identifier].filterOptions.index : emptyArray

export const getCurrentSorting = (state: State, identifier: t.Identifier): string =>
  state[identifier] ? state[identifier].filterValues.sorting : ''

const defaultPriceRange: [number, number] = [0, 100]
export const getPriceRange = (state: State, identifier: t.Identifier): [number, number] =>
  state[identifier] ? state[identifier].filterOptions.price || defaultPriceRange : defaultPriceRange

export const getTags = (state: State, identifier: t.Identifier): string[] =>
  state[identifier] ? state[identifier].filterValues.tags : emptyArray

export const getTagDict: (
  state: State,
  identifier: string
) => Record<string, true> = createReSelector(getTags, (tags) => {
  const dict: Record<string, true> = {}
  for (const tag of tags) dict[tag] = true
  return dict
})((_, id) => id)

export const getFilterValue = (
  state: State,
  identifier: t.Identifier,
  key: t.FilterKey
): t.FilterValue[] => {
  if (!state[identifier]) return emptyArray
  const v = state[identifier].filterValues
  if (v[key]) return v[key]
  return v.dynamicFilters[key] || emptyArray
}

// Filter

export const getFilterOptions = (
  state: State,
  identifier: t.Identifier,
  key: t.FilterKey
): t.FilterOption[] => {
  if (!state[identifier]) return emptyArray
  const o = state[identifier].filterOptions
  if (o[key]) return o[key]
  return o.dynamicFilters[key] || emptyArray
}

export const getFilter: (
  state: State,
  identifier: t.Identifier,
  key: t.FilterKey
) => t.Filter = createReSelector(
  (_, identifier: t.Identifier) => identifier,
  (_, __, key: t.FilterKey) => key,
  getFilterValue,
  getFilterOptions,
  (identifier, key, value, options) => ({ identifier, key, value, options })
)((_, id, key) => `${id}-${key}`)

export const getQueryID = (state: State, identifier: t.Identifier) =>
  state[identifier] ? state[identifier].queryID : ''

// utils

export const getNumHits = (state: State, identifier: t.Identifier): number =>
  state[identifier] ? state[identifier].numHits : 0

export const canReset: (state: State, identifier: t.Identifier) => boolean = createReSelector(
  getFilterValues,
  getResetFilterValues,
  (v1, v2) => !filterValuesUtils.areFilterValuesEqual(v1, v2)
)((_, id) => `${id}`)

export const getDynamicFiltersDefinitions = (
  state: State,
  identifier: t.Identifier
): t.DynamicFilterDefinition[] => {
  return state[identifier] ? state[identifier].dynamicFilters || emptyArray : emptyArray
}

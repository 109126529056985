import * as React from 'react'
import styled from 'styled-components'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import Link from 'theme/atoms/Link'
import Markdown from 'theme/atoms/Markdown'
import useInView from 'hooks/useInView'
import * as actions from './actions'
import * as t from './types'

export default function Image(props: t.Props) {
  let ref,image = ''
  // if image is rendered by TextWithImage-module
  if (props.imageWidth !== undefined) {
    [ref, image] = useLazyImageSrc(
      props.context.optImg,
      props.context.base64,
      100,
    )
  } else {
    [ref, image] = useLazyImageSrc(
      props.context.optImg,
      props.context.base64,
      100,
    )
  }

  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  // $FlowFixMe
  const attrs: any = !props.linkAttributes
    ? {}
    : props.linkAttributes.reduce((p, n) => (p[n.key] = n.value) && p, {})

  const handleClick = () => props.eecTrack && actions.click(props)
  return (
    <Wrapper ref={wrapperRef} className="Image" hasLink={Boolean(props.link)} onClick={handleClick}>
      <Link className="image-wrapper" to={props.link} {...attrs}>
        <img ref={ref} src={image} alt={props.alt} />
      </Link>
      {props.label && (
        <div className="description">
          <Markdown centered children={props.label} />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > .image-wrapper {
    cursor: ${(props) => (props.hasLink ? 'pointer' : 'auto')};
    display: block;
    width: 100%;
    line-height: 1;
    > img {
      width: 100%;
    }
  }
  > .description {
    p {
      margin: 0;
      font-size: 14px !important;
    }
  }
`

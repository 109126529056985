import styled from 'styled-components'
import pt from 'prop-types'

let Button = styled.button`
  font-size: 1em;
  padding: 0.25em 1em;
  cursor: pointer;

  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  background: white;
  color: ${(props) => (props.secondary ? '#a7a9ac' : '#993452')};
  border: 2px solid ${(props) => (props.secondary ? '#a7a9ac' : '#993452')};

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.filled &&
    `
    background: ${props.secondary ? '#a7a9ac' : '#993452'};
    color: white;
  `}

  ${(props) =>
    props.bold &&
    `
    font-weight: bold;
  `}

  ${(props) =>
    props.flat &&
    `
    background: none;
    border: none;
  `}

  ${(props) =>
    props.rounded &&
    `
    border-radius: 30px;
  `}

  ${(props) =>
    props.roundedRight &&
    `
    border-radius: 0 30px 30px 0;
  `}

  ${(props) =>
    props.disable &&
    `
    cursor: not-allowed;
    background: grey;
    border-color: grey;
  `}

  ${(props) =>
    props.fatt &&
    `
    padding: 0.5em 1em;
    font-size: 1.3em;
  `}

  /* center svg */
  svg {
    vertical-align: middle;
    margin-bottom: 2px;
  }
`

Button.propTypes = {
  secondary: pt.bool,
  filled: pt.bool,
  flat: pt.bool,
  bold: pt.bool,
  rounded: pt.bool,
  roundedRight: pt.bool,
  fullWidth: pt.bool,
  disable: pt.bool,
  fatt: pt.bool,
}

export default Button

import { addRule, dispatchEvent } from 'redux-ruleset'
import * as tracking from 'modules/tracking'
import config from 'config'
import getParameterByName from 'utils/getParameterByName'

// modules
import * as navigation from 'modules/navigation'
import * as products from 'modules/products'
import * as wishlist from 'modules/wishlist'
import * as user from 'modules/user'

// widgets
import * as ProductWidget from 'theme/molecules/ProductWidget/actions'
import * as Header from 'theme/_core/Header/actions'
import * as Sidebar from 'theme/_core/Sidebar/actions'
import * as Banner from 'theme/organisms/Banner/actions'
import * as Image from 'theme/organisms/Image/actions'
import * as CategoryBanner from 'theme/organisms/CategoryBanner/actions'
import * as CategoryBannerBorder from 'theme/organisms/CategoryBannerBorder/actions'
import * as CategoryBannerBorderHorizontal from 'theme/organisms/CategoryBannerBorderHorizontal/actions'
import * as CategoryBannerRounded from 'theme/organisms/CategoryBannerRounded/actions'
import * as ImageWithLinks from 'theme/organisms/ImageWithLinks/actions'
import * as SimpleQuestion from 'theme/organisms/SimpleQuestion/actions'
import * as CurratedSearchWidget from 'theme/organisms/CurratedSearchWidget/actions'
import * as Figurtypen from 'theme/organisms/Figurtypen/actions'
import * as BannerText from 'theme/organisms/BannerText/actions'
import * as BannerBrand from 'theme/organisms/BannerBrand/actions'
import * as Button from 'theme/organisms/Button/actions'
import * as CategoryTeaserList from 'theme/organisms/CategoryTeaserList/actions'
import * as FullwidthHeroBanner from 'theme/organisms/FullwidthHeroBanner/actions'
import * as Newsletter from 'theme/organisms/Newsletter/actions'
import * as Sizebar from 'theme/organisms/Sizebar/actions'
import * as Squarelovin from 'theme/organisms/Squarelovin/actions'
import * as Suggestion from 'theme/organisms/Suggestion/actions'

declare global {
  interface Window {
    rlxABMode: any
    abbranch: any
  }
}

const pushToAnalytics = (event: any) => dispatchEvent(tracking.sendEvent(event))
const bufferPushToAnalytics = (event: any, context: tracking.EventContext) =>
  dispatchEvent(tracking.sendBufferedEvent(event, context))

if (config.features.cookiepro && process.env.NODE_ENV !== 'development') {
  addRule({
    id: 'tracking/ADD_COOKIE_PRO_SCRIPT',
    target: navigation.LOCATION_CHANGE,
    output: '#script-add',
    addOnce: true,
    condition: () => !window.location.href.includes('#rlxNativeApp'),
    consequence: () => {
      // @ts-ignore
      window.OptanonWrapper = () => null
      const script = document.createElement('script')
      script.src = 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
      script.async = true
      script.setAttribute('data-domain-script', 'b30a4f41-9b8a-42d9-9454-bb320992810d')
      document.head.appendChild(script)
    },
  })
}


addRule({
  id: 'tracking/LIST_CLICKOUT',
  output: tracking.SEND_EVENT,
  target: ProductWidget.CLICK,
  condition: (action) => !action.payload.groupedId,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'triggeredGenericEvent',
        eventCategory: 'Merchant-Clickout',
        eventAction: action.payload.merchantName,
        eventLabel: action.payload.objectID,
        eventRequiresCustomisation: true,
      })
    )
  },
})

addRule({
  id: 'tracking/filter/SET_PAGE',
  output: tracking.SEND_EVENT,
  target: products.SET_PAGE,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'triggeredGenericEvent',
        eventCategory: 'Pagination',
        eventAction: action.payload + 1,
        eventLabel: 'user',
        eventRequiresCustomisation: false,
      })
    )
  },
})

addRule({
  id: 'tracking/filter/TOGGLE_FILTER',
  output: tracking.SEND_EVENT,
  target: products.TOGGLE_FILTER_OPTION,
  position: 'BEFORE',
  consequence: (action, { getState, effect }) => {
    const state = getState()
    const { identifier, filterKey } = action.meta
    const values = products.getFilterValue(state.products, identifier, filterKey)
    const on = !values.includes(action.payload)
    effect(() =>
      pushToAnalytics({
        event: 'TOGGLE_FILTER',
        eventCategory: 'filter-navigation',
        eventAction: filterKey,
        eventLabel: action.payload + (on ? '_on' : '_off'),
        eventRequiresCustomisation: false,
      })
    )
  },
})

addRule({
  id: 'tracking/filter/TOGGLE_CATEGORY',
  output: tracking.SEND_EVENT,
  target: products.TOGGLE_CATEGORY,
  position: 'BEFORE',
  consequence: (action, { getState, effect }) => {
    const state = getState()
    const { identifier } = action.meta
    const filterValues = products.getFilterValues(state.products, identifier)
    const on = filterValues.category !== action.payload?.categoryPath
    effect(() =>
      pushToAnalytics({
        event: 'TOGGLE_FILTER',
        eventCategory: 'filter-navigation',
        eventAction: 'category',
        eventLabel: action.payload + (on ? '_on' : '_off'),
        eventRequiresCustomisation: false,
      })
    )
  },
})

addRule({
  id: 'tracking/filter/TOGGLE_TAG',
  output: tracking.SEND_EVENT,
  target: products.TOGGLE_TAG,
  position: 'BEFORE',
  consequence: (action, { getState, effect }) => {
    const state = getState()
    const { identifier } = action.meta
    const filterValues = products.getFilterValues(state.products, identifier)
    const on = !filterValues.tags.includes(action.payload)
    effect(() =>
      pushToAnalytics({
        event: 'TOGGLE_FILTER',
        eventCategory: 'filter-navigation',
        eventAction: 'tags',
        eventLabel: action.payload + (on ? '_on' : '_off'),
        eventRequiresCustomisation: false,
      })
    )
  },
})

addRule({
  id: 'tracking/filter/SET_PRICE_RANGE',
  output: tracking.SEND_EVENT,
  target: products.SET_PRICE_RANGE,
  consequence: (action, { effect }) => {
    const [min, max] = action.payload || [null, null]
    effect(() =>
      pushToAnalytics({
        event: 'TOGGLE_FILTER',
        eventCategory: 'filter-navigation',
        eventAction: 'price',
        eventLabel: min ? `${min}€ - ${max}€` : 'null',
        eventRequiresCustomisation: false,
      })
    )
  },
})

addRule({
  id: 'tracking/LOCATION_CHANGE',
  output: tracking.SEND_EVENT,
  target: navigation.LOCATION_CHANGE,
  position: 'BEFORE',
  condition: (action) => {
    const next = action.payload
    const prev = action.meta.prevLocation

    // first page view
    if (!prev) return true

    // true when query changes
    if (next.pathname.includes('/search') && prev.pathname.includes('/search')) {
      return getParameterByName('q', next.hash, true) !== getParameterByName('q', prev.hash, true)
    }

    // true when navigating to other route
    return next.pathname !== prev.pathname
  },
  consequence: (action, { effect }) => {
    const { pathname } = action.payload
    const initial = !action.meta.prevLocation
    let pagetype = 'Other Pages'

    if (pathname === config.prefix + '/') pagetype = 'Homepage'
    else if (pathname.includes('/search')) pagetype = 'Internal Search'
    else if (pathname.includes('/shop/')) pagetype = 'Product-Categories'
    else if (pathname.includes('/sale')) pagetype = 'Product-Categories'
    else if (pathname.includes('/magazin/a/')) pagetype = 'Magazine-Articles'
    else if (pathname.includes('/magazin')) pagetype = 'Magazine-Categories'

    const event = {
      'rlx-pagetype': pagetype,
      event: initial ? 'rlx-pageview-initial' : 'rlx-pageview',
      'rlx-ab-variant': window.rlxABMode,
      branch: window.abbranch || 'master',
    }

    if (pathname.includes('/search')) {
      // $FlowFixMe
      event['rlx-searchquery'] = getParameterByName('q', action.payload.hash, true)
    }

    effect(() => pushToAnalytics(event))
  },
})

addRule({
  id: 'tracking/USER_STATUS',
  output: tracking.SEND_EVENT,
  target: user.FINISH_SETUP,
  consequence: (_, { getState, effect }) => {
    const state = getState()
    const userData = user.getUser(state.user)
    if (userData.hasNewsletterRequested) {
      effect(() =>
        pushToAnalytics({
          event: 'triggeredGenericEvent',
          eventCategory: 'Newsletter',
          eventAction: 'Anmeldung_Success',
          eventLabel: 'Checkbox_Registrierung',
        })
      )
    }
    effect(() =>
      pushToAnalytics({
        event: 'user-status',
        'login-state': userData.isLoggedIn ? 'logged-in' : 'logged-out',
      })
    )
  },
})

addRule({
  id: 'tracking/EMPTY_PRODUCT_LIST',
  output: tracking.SEND_EVENT,
  target: 'PARTIAL_STATE_UPDATES',
  condition: (_, { getState }) => {
    if (typeof window !== 'undefined') {
      if (window.location.pathname.includes('/shop/')) {
        const state = getState()
        const numHits = products.getNumHits(state.products, 'fashion-product-list')
        if (numHits === 0) return true
      }
    }
    return false
  },
  consequence: (_, { effect }) => {
    effect(() => pushToAnalytics({ event: 'empty-category' }))
  },
})

// ADVANCED ECOMMERCE


addRule({
  id: 'tracking/SCROLL_PRODUCT_INTO_VIEW',
  output: tracking.SEND_BUFFERED_EVENT,
  target: ProductWidget.SCROLL_INTO_VIEW,
  consequence: (action, { effect }) => {
    const event = {
      event: 'eec.impressionView',
      ecommerce: {
        impressions: [
          {
            id: action.payload.groupedId || action.payload.objectID,
            name: action.payload.productName,
            category: window.location.pathname,
            list: action.meta.listName,
            position: action.meta.index + 1,
            brand: action.payload.productManufacturerBrand,
            dimension14: action.payload._tags?.includes('directbuy') ? 'direct' : 'clickout',
            dimension15: action.payload.merchantName,
          },
        ],
      },
    }
    const context = {
      delay: 5000,
      itemKey: ['ecommerce', 'impressions'],
      bufferId: 'tracking/SCROLL_PRODUCT_INTO_VIEW',
    }
    effect(() => bufferPushToAnalytics(event, context))
  },
})

addRule({
  id: 'tracking/PRODUCT_CLICK',
  output: tracking.SEND_EVENT,
  target: ProductWidget.CLICK,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'eec.impressionClick',
        ecommerce: {
          click: {
            actionField: {
              list: action.meta.listName,
            },
            products: [
              {
                id: action.payload.groupedId || action.payload.objectID,
                name: action.payload.productName,
                category: window.location.pathname,
                position: action.meta.index + 1,
                brand: action.payload.productManufacturerBrand,
                dimension14: action.payload._tags?.includes('directbuy') ? 'direct' : 'clickout',
                dimension15: action.payload.merchantName,
              },
            ],
          },
        },
      })
    )
  },
})

addRule({
  id: 'tracking/CMS_CLICK',
  output: tracking.SEND_EVENT,
  target: [
    Banner.CLICK,
    SimpleQuestion.CLICK,
    CurratedSearchWidget.CLICK,
    Image.CLICK,
    ImageWithLinks.CLICK,
    CategoryBanner.CLICK,
    CategoryBannerBorder.CLICK,
    CategoryBannerBorderHorizontal.CLICK,
    CategoryBannerRounded.CLICK,
    Figurtypen.CLICK,
    BannerText.CLICK,
    BannerBrand.CLICK,
    Button.CLICK,
    CategoryTeaserList.CLICK,
    FullwidthHeroBanner.CLICK,
    Newsletter.CLICK,
    Sizebar.CLICK,
    Squarelovin.CLICK,
    Suggestion.CLICK,
  ],
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'eec.promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [{ name: action.payload.gridArea }],
          },
        },
      })
    )
  },
})

addRule({
  id: 'tracking/SCROLL_CMS_INTO_VIEW',
  output: tracking.SEND_BUFFERED_EVENT,
  target: [
    Banner.SCROLL_INTO_VIEW,
    SimpleQuestion.SCROLL_INTO_VIEW,
    CurratedSearchWidget.SCROLL_INTO_VIEW,
    Image.SCROLL_INTO_VIEW,
    ImageWithLinks.SCROLL_INTO_VIEW,
    CategoryBanner.SCROLL_INTO_VIEW,
    CategoryBannerBorder.SCROLL_INTO_VIEW,
    CategoryBannerBorderHorizontal.SCROLL_INTO_VIEW,
    CategoryBannerRounded.SCROLL_INTO_VIEW,
    Figurtypen.SCROLL_INTO_VIEW,
    BannerText.SCROLL_INTO_VIEW,
    BannerBrand.SCROLL_INTO_VIEW,
    Button.SCROLL_INTO_VIEW,
    CategoryTeaserList.SCROLL_INTO_VIEW,
    FullwidthHeroBanner.SCROLL_INTO_VIEW,
    Newsletter.SCROLL_INTO_VIEW,
    Sizebar.SCROLL_INTO_VIEW,
    Squarelovin.SCROLL_INTO_VIEW,
    Suggestion.SCROLL_INTO_VIEW,
  ],
  consequence: (action, { effect }) => {
    const event = {
      event: 'eec.promotionView',
      ecommerce: {
        promoView: {
          promotions: [{ name: action.payload.gridArea }],
        },
      },
    }
    const context = {
      delay: 5000,
      itemKey: ['ecommerce', 'promoView', 'promotions'],
      bufferId: 'tracking/SCROLL_CMS_INTO_VIEW',
    }
    effect(() => bufferPushToAnalytics(event, context))
  },
})


addRule({
  id: 'tracking/MANUAL_SEARCH',
  output: tracking.SEND_EVENT,
  target: Header.TRIGGER_SEARCH,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        'rlx-pagetype': 'Internal Search',
        event: 'rlx-pageview',
        'rlx-searchquery': action.payload,
        'rlx-manualsearch': 1,
      })
    )
  },
})

addRule({
  id: 'tracking/ADD_WISHLIST_PRODUCT',
  output: tracking.SEND_EVENT,
  target: [wishlist.ADD_GROUPED_ITEM_SUCCESS, wishlist.ADD_LISTING_ITEM_SUCCESS],
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'Wishlist',
        eventCategory: 'Wishlist',
        eventAction: 'Added_to_Wishlist',
        eventLabel: action.payload.groupedId || action.payload.productId,
        eventRequiresCustomisation: false,
      })
    )
  },
})

addRule({
  id: 'tracking/REMOVE_WISHLIST_PRODUCT',
  output: tracking.SEND_EVENT,
  target: wishlist.REMOVE_ITEM,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'Wishlist',
        eventCategory: 'Wishlist',
        eventAction: 'Delete_from_Wishlist',
        eventLabel: action.payload.groupedId || action.payload.productId,
        eventRequiresCustomisation: false,
      })
    )
  },
})

addRule({
  id: 'tracking/SIDEBAR_CLICK',
  output: tracking.SEND_EVENT,
  target: Sidebar.CLICK_ITEM,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'triggeredGenericEvent',
        eventCategory: 'Main Navigation',
        eventAction: action.payload,
        eventRequiresCustomisation: false,
      })
    )
  },
})

// TODO: rethink with redux-ruleset v2
addRule({
  id: 'tracking/INFORMED_CLICKOUT',
  output: tracking.SEND_EVENT,
  target: ['ProductWidget/CLICK'],
  condition: (action) => Boolean(action.payload.groupedId),
  addWhen: function*(next, { context }) {
    let clickedProducts = 0
    const initialTime = Date.now()
    const result: any = yield next(
      [
        products.TOGGLE_FILTER_OPTION,
        products.TOGGLE_TAG,
        products.SET_PRICE_RANGE,
        products.SET_PAGE,
        'ProductWidget/CLICK',
      ],
      (action) => {
        // was called for every action???
        if (context.get('called')) return
        const newTime = Date.now()
        if (newTime - initialTime > 60 * 1000) {
          return 'ADD_RULE_BEFORE'
        }
        switch (action.type) {
          case products.TOGGLE_FILTER_OPTION:
          case products.SET_PRICE_RANGE:
          case products.SET_PAGE:
          case products.TOGGLE_TAG:
            return 'ADD_RULE'
          case 'ProductWidget/CLICK': {
            if (clickedProducts >= 2 && !action.payload.groupedId) return 'ADD_RULE'
            else clickedProducts++
          }
        }
        return false
      }
    )
    context.set('called', true)
    return result
  },
  addUntil: function*(next) {
    yield next(['ProductWidget/CLICK'], (action) => Boolean(action.payload.groupedId))
    return 'REMOVE_RULE'
  },
  // clickout was not fired???
  addOnce: true,
  consequence: (_, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'triggeredGenericEvent',
        eventCategory: 'Prospect',
        eventAction: window.location.pathname,
        eventLabel: '',
        eventRequiresCustomisation: true,
      })
    )
  },
})

addRule({
  id: 'tracking/NEWSLETTER_SUBSCRIPTION',
  target: 'Footer/SUBSCRIBE',
  output: tracking.SEND_EVENT,
  consequence: (_, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'triggeredGenericEvent',
        eventCategory: 'Newsletter',
        eventAction: 'Footer_Subscribed',
        eventLabel: window.location.pathname,
      })
    )
  },
})

addRule({
  id: 'tracking/FAVOURITE_FILTER_OPEN',
  target: 'ProductListing/OPEN_FAVOURITE_FILTER',
  output: tracking.SEND_EVENT,
  consequence: () =>
    pushToAnalytics({
      event: 'triggeredGenericEvent',
      eventCategory: 'filter-navigation',
      eventAction: 'Lieblingsfilter',
      eventLabel: 'open',
    }),
})

addRule({
  id: 'tracking/FAVOURITE_FILTER_CLOSE',
  target: 'ProductListing/CLOSE_FAVOURITE_FILTER',
  output: tracking.SEND_EVENT,
  consequence: (action) =>
    pushToAnalytics({
      event: 'triggeredGenericEvent',
      eventCategory: 'filter-navigation',
      eventAction: 'Lieblingsfilter Usage',
      eventLabel: action.payload,
    }),
})

// TRACK LOGIN TRY

// add flag when user starts login process
addRule({
  id: 'feature/FLAG_LOGIN_REQUEST',
  target: 'user/LOGIN',
  output: '#localstorage',
  consequence: () => {
    localStorage.setItem('feature/FLAG_LOGIN_REQUEST', 'true')
  },
})

if (typeof window !== 'undefined' && localStorage.getItem('feature/FLAG_LOGIN_REQUEST')) {
  if (!window.location.pathname.includes('/callback-auth/')) {
    addRule({
      id: 'tracking/USER_LOGIN_SUCCESS_STATUS',
      target: 'user/FINISH_SETUP',
      output: tracking.SEND_EVENT,
      consequence: (_, { getState }) => {
        const state = getState()
        localStorage.removeItem('feature/FLAG_LOGIN_REQUEST')
        return pushToAnalytics({
          event: 'triggeredGenericEvent',
          eventCategory: 'login',
          eventAction: 'Triggered-Login',
          eventLabel: state.user.data.isLoggedIn ? 'Success' : 'No-Success',
        })
      },
    })
  }
}

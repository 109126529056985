// @flow
import * as t from '../types'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  query: string
  setQuery: (query: string) => a.SetQuery
}

type DP = {
  setQuery: typeof a.setQuery
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useQuery',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    query: s.getQuery(state, props.identifier),
  }),
  mapDispatch: {
    setQuery: a.setQuery,
  },
  transformDispatch: {
    setQuery: (fn, sp, props) => (query) => fn(props.identifier, query),
  },
}

export default function useQuery(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect(input, config)
  return hook
}

import * as t from './types'
import * as at from './const'
import { defaultFilterValues } from './utils/filter-values-utils'

export const init = (
  identifier: t.Identifier,
  initialValues: Partial<t.FilterValues> = {},
  resetValues: Partial<t.FilterValues> | null = null,
  dynamicFilters: t.DynamicFilterDefinition[] = []
) => {
  dynamicFilters = dynamicFilters.filter((row) => row.label)
  const values: t.FilterValues = Object.assign({}, defaultFilterValues, initialValues)
  const _resetValues: t.FilterValues = resetValues
    ? Object.assign({}, defaultFilterValues, resetValues)
    : values
  for (const f of dynamicFilters) {
    if (!values.dynamicFilters[f.path]) values.dynamicFilters[f.path] = []
    if (!_resetValues.dynamicFilters[f.path]) _resetValues.dynamicFilters[f.path] = []
  }
  return {
    type: at.INIT_LIST,
    meta: { identifier, resetValues: _resetValues, dynamicFilters },
    payload: Object.assign({}, defaultFilterValues, initialValues),
  }
}

export const fetchRequest = (
  identifier: t.Identifier,
  filterValues: t.FilterValues,
  dynamicFilters: t.DynamicFilterDefinition[],
  mode: t.FetchMode
) => ({
  type: at.FETCH_REQUEST,
  meta: { identifier, filterValues, dynamicFilters, mode },
})

export const fetchSuccess = (
  identifier: t.Identifier,
  filterValues: t.FilterValues,
  dynamicFilters: t.DynamicFilterDefinition[],
  mode: t.FetchMode,
  result: t.api.Fetch
) => ({
  type: at.FETCH_SUCCESS,
  meta: { identifier, filterValues, dynamicFilters, mode },
  payload: result,
})

export const fetchFailure = (
  identifier: t.Identifier,
  filterValues: t.FilterValues,
  dynamicFilters: t.DynamicFilterDefinition[],
  mode: t.FetchMode,
  error: string
) => ({
  type: at.FETCH_FAILURE,
  meta: { identifier, filterValues, dynamicFilters, mode },
  payload: error,
})

export const setQuery = (identifier: t.Identifier, query: string) => ({
  type: at.SET_QUERY,
  meta: { identifier },
  payload: query,
})

export const toggleCategory = (identifier: t.Identifier, category?: t.CategoryOption) => ({
  type: at.TOGGLE_CATEGORY,
  meta: { identifier },
  payload: category,
})

export const setPage = (identifier: t.Identifier, page: number) => ({
  type: at.SET_PAGE,
  meta: { identifier },
  payload: page,
})

export const toggleFilterOption = (
  identifier: t.Identifier,
  filterKey: t.FilterKey,
  opt: t.FilterOption
) => ({
  type: at.TOGGLE_FILTER_OPTION,
  meta: { identifier, filterKey },
  payload: opt,
})

export const setContext = (identifier: t.Identifier, context: string) => ({
  type: at.SET_CONTEXT,
  meta: { identifier },
  payload: context,
})

export const setAnalyticTags = (identifier: t.Identifier, tags: string[]) => ({
  type: at.SET_ANALYTIC_TAGS,
  meta: { identifier },
  payload: tags,
})

export const setPrice = (identifier: t.Identifier, range: [number, number] | null) => ({
  type: at.SET_PRICE_RANGE,
  meta: { identifier },
  payload: range,
})

export const setIndex = (identifier: t.Identifier, index: string) => ({
  type: at.SET_INDEX,
  meta: { identifier },
  payload: index,
})

export const toggleTag = (identifier: t.Identifier, tag: string) => ({
  type: at.TOGGLE_TAG,
  meta: { identifier },
  payload: tag,
})

export const clearFilterValues = (identifier: t.Identifier, key?: string) => ({
  type: at.CLEAR_FILTER_VALUES,
  meta: { identifier },
  payload: key,
})

export const setSorting = (identifier: t.Identifier, attribute: string) => ({
  type: at.SET_SORTING,
  meta: { identifier },
  payload: attribute,
})

export type Init = ReturnType<typeof init>
export type FetchRequest = ReturnType<typeof fetchRequest>
export type FetchSuccess = ReturnType<typeof fetchSuccess>
export type FetchFailure = ReturnType<typeof fetchFailure>
export type SetQuery = ReturnType<typeof setQuery>
export type ToggleCategory = ReturnType<typeof toggleCategory>
export type SetPage = ReturnType<typeof setPage>
export type ToggleFilterOption = ReturnType<typeof toggleFilterOption>
export type SetContext = ReturnType<typeof setContext>
export type SetAnalyticTags = ReturnType<typeof setAnalyticTags>
export type SetPrice = ReturnType<typeof setPrice>
export type SetIndex = ReturnType<typeof setIndex>
export type ToggleTag = ReturnType<typeof toggleTag>
export type ClearFilterValues = ReturnType<typeof clearFilterValues>
export type SetSorting = ReturnType<typeof setSorting>

export type Action =
  | Init
  | FetchRequest
  | FetchSuccess
  | FetchFailure
  | SetQuery
  | ToggleCategory
  | SetPage
  | ToggleFilterOption
  | SetContext
  | SetAnalyticTags
  | SetPrice
  | SetIndex
  | ToggleTag
  | ClearFilterValues
  | SetSorting

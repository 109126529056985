import './rules'
import store from 'store'
import reducer, { State } from './reducer'
import { Action } from './actions'

store.injectReducer(store, 'lastSeenProducts', reducer)

declare global {
  interface RootState {
    lastSeenProducts: State
  }
  interface ModuleActions {
    lastSeenProducts: Action
  }
  interface RootReducers {
    lastSeenProducts: typeof reducer
  }
}

export { default as useLastSeenProductIds } from './hooks/useLastSeenProductsIds'

import { addRule } from 'redux-ruleset'
import config from 'config'
import { LOCATION_CHANGE } from 'modules/navigation'

if (config.features.cookiepro && process.env.NODE_ENV !== 'development') {
  addRule({
    id: 'tracking/ADD_COOKIE_PRO_SCRIPT',
    target: LOCATION_CHANGE,
    output: '#script-add',
    addOnce: true,
    condition: () => !window.location.href.includes('#rlxNativeApp'),
    consequence: () => {
      // @ts-ignore
      window.OptanonWrapper = () => null
      const script = document.createElement('script')
      script.src = 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
      script.async = true
      script.setAttribute('data-domain-script', 'b30a4f41-9b8a-42d9-9454-bb320992810d')
      document.head.appendChild(script)
    },
  })
}

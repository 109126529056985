import * as t from './types'
import { dispatchEvent } from 'redux-ruleset'

export const CLICK: 'ProductWidget/CLICK' = 'ProductWidget/CLICK'
export const SCROLL_INTO_VIEW: 'ProductWidget/SCROLL_INTO_VIEW' = 'ProductWidget/SCROLL_INTO_VIEW'
export const HEART_CLICK: 'ProductWidget/HEART_CLICK' = 'ProductWidget/HEART_CLICK'

/** @firescoutMockFn molecules-ProductWidget.action-click */
export const click = (product: t.Product, listName: string, index: number, queryID?: string) =>
  dispatchEvent({
    type: CLICK,
    meta: { listName, index, queryID },
    payload: product,
  })

/** @firescoutMockFn molecules-ProductWidget.action-scrollIntoView */
export const scrollIntoView = (
  product: t.Product,
  listName: string,
  index: number,
  queryID?: string
) =>
  dispatchEvent({
    type: SCROLL_INTO_VIEW,
    meta: { listName, index, queryID },
    payload: product,
  })

export const heartClick = (
  product: t.Product,
  listName: string,
  index: number,
  wishlistSelected: boolean,
  queryID?: string
) =>
  dispatchEvent({
    type: HEART_CLICK,
    meta: { listName, index, wishlistSelected, queryID },
    payload: product,
  })

export type Click = ReturnType<typeof click>
export type ScrollIntoView = ReturnType<typeof scrollIntoView>
export type HeartClick = ReturnType<typeof heartClick>

declare global {
  interface RulesetDispatchEvents {
    'molecules/ProductWidget': Click | ScrollIntoView | HeartClick
  }
}

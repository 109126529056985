import * as React from 'react'
// import * as components from 'theme/organisms'
import Text from 'theme/organisms/Text'
import Image from 'theme/organisms/Image'
import Button from 'theme/organisms/Button'
import styled from 'styled-components'
import Container from 'theme/atoms/Container'   

type Props = {
  story: any
  small?: boolean
}

export default function Story({ story, small }: Props) {
  if (!story) return null
  return (
    <Wrapper as="section" id={story.contentfulId} className="Story" css={story.css} small={small}>
      {story.components.map((id) => {
        const c = story.dict[id] || {}
        if(!story.componentDict) story.componentDict = {}
        let Component = story.componentDict[c.name]

        if(c.name === 'Text') Component = Text
        if(c.name === 'Image') Component = Image
        if(c.name === 'Button') Component = Button

        if (!c.name) return null

        if (!Component) {
          let text = ''
          if(typeof window !== 'undefined' && location.pathname.includes('/preview')) {
            text = `Component "${c.name}" not found`
          }
          return (
            <NotFound
              className={c.props.gridArea + ' CmsWrapper'}
              gridArea={c.props.gridArea}
              key={id}
            >
              {text}
            </NotFound>
          )
        }

        return (
          <ComponentWrapper
            key={id}
            id={c.props.gridArea}
            className={c.props.gridArea + ' CmsWrapper'}
          >
            <Component {...c.props} />
          </ComponentWrapper>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  display: grid;
  ${(props) => props.css};
`

const NotFound = styled.div`
  grid-area: ${(props) => props.gridArea};
`

const ComponentWrapper = styled.section`
  line-height: 0.7;
  width: 100%;
  height: 100%;

  > * {
    width: 100%;
    height: 100%;
  }
`

import * as t from '../types'
import * as s from '../selectors'
// import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  data: t.DynamicFilterDefinition[]
}

const config: Config<Input, Output, State, never> = {
  moduleKey: 'products',
  name: 'products/useDynamicFilterDefinitions',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    data: s.getDynamicFiltersDefinitions(state, props.identifier),
  }),
}

export default function useDynamicFilterDefinitions(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect(input, config)
  return hook
}

import * as t from '../types'

declare global {
  interface Window {
    dataLayer: any
  }
}

/** @firescoutMockFn tracking.sendEvent */
export const pushToAnalytics = (event: Record<string, unknown>) => {
  // console.log(event)
  if (typeof window !== 'undefined') {
    window.dataLayer && window.dataLayer.push(event)
  }
}

let buffer = {}
/** @firescoutMockFn tracking.sendBufferedEvent */
export const bufferPushToAnalytics = (
  event: Record<string, unknown>,
  { delay, itemKey, bufferId }: t.EventContext
) => {
  if (typeof itemKey === 'string') itemKey = [itemKey]
  if (buffer[bufferId]) {
    let list = buffer[bufferId]
    let values: any = event
    for (let key of itemKey) {
      list = list[key]
      values = values[key]
    }
    list.push(...values)
  } else {
    buffer[bufferId] = event
    const flush = () => {
      pushToAnalytics(buffer[bufferId])
      buffer[bufferId] = undefined
      window.removeEventListener('beforeunload', flush)
    }
    window.addEventListener('beforeunload', flush)

    setTimeout(() => flush(), delay)
  }
}

import * as t from '../types'
import { createSearchHelper, HierarchicalTreeItem } from 'utils/searchClient'
import config from 'config'

const searchKeys = {
  size: 'wunderSizes',
  brand: 'productManufacturerBrand',
  color: 'wunderColors',
  shop: 'merchantName',
  price: 'productPrice',
  wunderCategories: 'wunderCategories',
  'tagGroups.Absatzart': 'tagGroups.Absatzart',
  'tagGroups.Absatzhöhen': 'tagGroups.Absatzhöhen',
  'tagGroups.Anlässe': 'tagGroups.Anlässe',
  'tagGroups.Ausschnitt': 'tagGroups.Ausschnitt',
  'tagGroups.BH-Typen': 'tagGroups.BH-Typen',
  'tagGroups.Besondere Marken': 'tagGroups.Besondere Marken',
  'tagGroups.Details': 'tagGroups.Details',
  'tagGroups.Figurtypen': 'tagGroups.Figurtypen',
  'tagGroups.Kuratierte Pages': 'tagGroups.Kuratierte Pages',
  'tagGroups.Materialien': 'tagGroups.Materialien',
  'tagGroups.Musterarten': 'tagGroups.Musterarten',
  'tagGroups.Passform': 'tagGroups.Passform',
  'tagGroups.Shapewear-Arten': 'tagGroups.Shapewear-Arten',
  'tagGroups.Stiltypen': 'tagGroups.Stiltypen',
  'tagGroups.Ärmellängen': 'tagGroups.Ärmellängen',
}

export async function fetchHelper(
  filterValues: t.FilterValues,
  dynamicFilters: t.DynamicFilterDefinition[]
) {
  const helper = createSearchHelper<t.api.Hit>(config.indexes.products, {
    disjunctiveFacets: Object.values(searchKeys)
      .concat(['objectID', 'gender', 'tagLabels'])
      .concat(dynamicFilters.map((f) => f.path)),
    hierarchicalFacets: [{
      name: 'categories',
      fields: [
        'wunderCategoriesHierarchical.lvl0',
        'wunderCategoriesHierarchical.lvl1',
      ]
    }],
    hitsPerPage: 24,
    maxValuesPerFacet: 100,
    attributesToRetrieve: [
      '_tags',
      'imageMediumURL',
      'productPrice',
      'productPriceOld',
      'productManufacturerBrand',
      'productName',
      'shippingAndHandling',
      'merchantName',
      'isOnSale',
      'salePercentage',
      'wunderSizes',
      'productColor',
      'deliveryTime',
      'groupedId',
      'wunderCategories',
      'gender',
      'filterColor',
      'wunderColors',
      'objectID',
    ],
  })

  if (filterValues.query) helper.setQuery(filterValues.query)
  if (filterValues.category) helper.setHierarchicalFacet('categories', filterValues.category)

  // to allow for TS curation(s) per category do pimp (i.e. tag) the 
  // TS search w/ context, e.g. of "category_{CONTENTFUL_ENTRY_ID}"
  if (filterValues.context) {
    helper.addOverrideTags([filterValues.context])
  }

  if (filterValues.page) helper.setPage(filterValues.page)
  if (filterValues.sorting) helper.setSorting(filterValues.sorting)

  for (const brand of filterValues.brand) {
    helper.addDisjunctiveFacet(searchKeys.brand, brand)
  }

  for (const size of filterValues.size) {
    helper.addDisjunctiveFacet(searchKeys.size, size)
  }

  for (const color of filterValues.color) {
    helper.addDisjunctiveFacet(searchKeys.color, color)
  }

  for (const shop of filterValues.shop) {
    helper.addDisjunctiveFacet(searchKeys.shop, shop)
  }

  for (const wunderCategories of filterValues.wunderCategories) {
    helper.addDisjunctiveFacet(searchKeys.wunderCategories, wunderCategories)
  }

  for (const objectId of filterValues.objectIds) {
    helper.addDisjunctiveFacet('objectID', objectId)
  }

  for (const tag of filterValues.tags) {
    helper.addDisjunctiveFacet('_tags', tag)
  }

  if (filterValues.price) {
    helper.addRangeFacet(searchKeys.price, filterValues.price[0], filterValues.price[1])
  }

  for (const key in filterValues.dynamicFilters) {
    for (const val of filterValues.dynamicFilters[key]) {
      helper.addDisjunctiveFacet(key, val)
    }
  }

  return helper
}

export async function fetch(
  filterValues: t.FilterValues,
  dynamicFilters: t.DynamicFilterDefinition[] = [],
  mode: t.FetchMode
): Promise<t.api.Fetch> {
  const helper = await fetchHelper(filterValues, dynamicFilters)

  const result = await helper.search()
  const response: t.api.Fetch = {
    brandOptions: result.facets[searchKeys.brand].counts.map((c) => c.value),
    categories: transform(result.hierarchicalFacets.categories),
    colorOptions: result.facets[searchKeys.color].counts.map((c) => c.value),
    dynamicFilterOptions: dynamicFilters.reduce((prev, next) => {
      if (result.facets[next.path]) {
        prev[next.path] = result.facets[next.path].counts.map((c) => c.value)
      }
      return prev
    }, {}),
    exhaustiveFacetsCount: true,
    exhaustiveNbHits: true,
    hits: result.hits,
    maxPrice: result.facets[searchKeys.price].stats.max || 0,
    minPrice: result.facets[searchKeys.price].stats.min || 0,
    mode: mode,
    numHits: result.totalHits,
    numPages: result.numPages,
    page: result.page,
    queryID: '',
    shopOptions: result.facets[searchKeys.shop].counts.map((c) => c.value),
    sizeOptions: result.facets[searchKeys.size].counts.map((c) => c.value),
    wunderCategoryOptions: result.facets[searchKeys.wunderCategories].counts.map((c) => c.value),
  }
  return response
}

let categoryPathToUrl: Record<string, string> | null = null
function getUrlFromCategoryPath(path: string): string {
  if (!categoryPathToUrl) {
    categoryPathToUrl = {}
    for (const key in config.categories) {
      categoryPathToUrl[config.categories[key].categoryPath] = config.categories[key].urlKey
    }
  }
  return categoryPathToUrl[path]
}

function transform(tree: HierarchicalTreeItem[]): t.CategoryOption[] {
  return tree.map(item => ({
    count: item.count,
    label: item.label,
    selected: item.selected,
    categoryPath: item.path,
    urlKey: getUrlFromCategoryPath(item.path),
    options: item.children ? transform(item.children) : []
  }))
    .filter(row => row.urlKey)
    .sort((a, b) => a.label.localeCompare(b.label))
}

export async function fetchFilterOptions(
  filterValues: t.FilterValues,
  filterKey: t.FilterKey,
  mode: t.FetchMode,
  query: string
) {
  const helper = await fetchHelper({ ...filterValues }, [])

  helper.addDisjunctiveFacet(searchKeys[filterKey], query)
  const result = await helper.search({
    facetQuery: `${searchKeys[filterKey]}:${query}`,
  })
  return result.facets[searchKeys[filterKey]].counts.map((hit) => hit.value)
}

import { dispatchEvent } from 'redux-ruleset'

export const CLICKOUT: 'ProductModal/CLICKOUT' = 'ProductModal/CLICKOUT'

export const clickout = (product: any) =>
  dispatchEvent({
    type: CLICKOUT,
    payload: product,
  })

export type Clickout = ReturnType<typeof clickout>

declare global {
  interface RulesetDispatchEvents {
    'core/ProductModal': Clickout
  }
}

import { addRule } from 'redux-ruleset'
import * as at from './const'

/**
 * When a product was added
 * Then I save the last 20 ids in local storage
 */
addRule({
  id: 'last-seen-products/ADD_TO_LOCAL_STORAGE',
  target: at.ADD_ID,
  output: '#localstorage',
  consequence: (_, { getState }) => {
    const state = getState()
    const ids = state.lastSeenProducts
    const result = ids.join(',')
    window.localStorage.setItem('last-seen-products', result)
  },
})

import 'modules/last-seen-products'
import { addRule } from 'redux-ruleset'
import { addId } from 'modules/last-seen-products/actions'

/**
 * When a product was clicked
 * And the product wa a directbuy product
 * Then I want add the ids to the last seen products module
 */
addRule({
  id: 'LOG_DIRECTBUY_WIDGET_CLICK',
  target: 'ProductWidget/CLICK',
  output: 'last-seen-products/ADD_ID',
  condition: (action) => Boolean(action.payload.groupedId),
  consequence: (action) => addId(action.payload.groupedId as string),
})

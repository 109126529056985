import * as t from '../types'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  data: t.FilterValues
  canReset: boolean
  toggleFilterOption: (filterKey: t.FilterKey, opt: t.FilterOption) => a.ToggleFilterOption
  setPrice: (price: [number, number] | null) => a.SetPrice
  clearFilterValues: () => a.ClearFilterValues
  toggleTag: (tag: string) => a.ToggleTag
  setIndex: (index: string) => a.SetIndex
  setSorting: (attribute: string) => a.SetSorting
}

type DP = {
  toggleFilterOption: typeof a.toggleFilterOption
  setPrice: typeof a.setPrice
  clearFilterValues: typeof a.clearFilterValues
  toggleTag: typeof a.toggleTag
  setIndex: typeof a.setIndex
  setSorting: typeof a.setSorting
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useFilterValues',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    data: s.getFilterValues(state, props.identifier),
    canReset: s.canReset(state, props.identifier),
  }),
  mapDispatch: {
    toggleFilterOption: a.toggleFilterOption,
    setPrice: a.setPrice,
    clearFilterValues: a.clearFilterValues,
    toggleTag: a.toggleTag,
    setIndex: a.setIndex,
    setSorting: a.setSorting,
  },
  transformDispatch: {
    toggleFilterOption: (fn, sp, props) => (filterKey, opt) => fn(props.identifier, filterKey, opt),
    setPrice: (fn, sp, props) => (price) => fn(props.identifier, price),
    clearFilterValues: (fn, sp, props) => () => fn(props.identifier),
    toggleTag: (fn, sp, props) => (tag) => fn(props.identifier, tag),
    setIndex: (fn, sp, props) => (index) => fn(props.identifier, index),
    setSorting: (fn, sp, props) => (attribute) => fn(props.identifier, attribute),
  },
}

export default function useFilterValues(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect(input, config)
  return hook
}

import { addRule } from 'redux-ruleset'
import { setSquarelovinId } from 'modules/ui/actions'

/**
 * When the Squarelovin popup opens
 * Then I want to save the id to the url hash
 */
addRule({
  id: 'feature/SQUARELOVIN_TO_URL',
  target: 'ui/SET_SQUARELOVIN_ID',
  output: '#url-hash',
  consequence: (action) => {
    let hash = window.location.hash

    // clear prev hash
    hash = hash.replace(/&?#?sqId=[^&?]*/g, '')
    if (hash && hash[0] !== '#') hash = '#' + hash

    if (action.payload) {
      if (!hash) hash = '#'
      else hash += '&'
      hash += `sqId=${action.payload}`
    }

    window.history.replaceState(null, '', window.location.pathname + window.location.search + hash)
  },
})

/**
 * When the url contains squarelovin hash
 * And no squarelovin id is set
 * Then I want to set the id from the url
 */
addRule({
  id: 'feature/SQUARELOVIN_FROM_URL',
  target: 'navigation/LOCATION_CHANGE',
  output: 'ui/SET_SQUARELOVIN_ID',
  condition: (_, { getState }) => {
    const state = getState()
    return !state.ui.squarelovinId
  },
  delay: 1000,
  consequence: (action) => {
    const match = action.payload.hash.match(/sqId=([^&]*)/)
    if (match) return setSquarelovinId(match[1])
    else return
  },
})

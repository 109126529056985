import 'intersection-observer'
import { useInView as useObserver } from 'react-intersection-observer'

export default function useInView(offset = 0, triggerOnce = true) {
  const [ref, inView]: any = useObserver({
    triggerOnce,
    rootMargin: `${offset}px 0px ${offset}px 0px`,
  })

  return [ref, inView]
}

import * as a from '../actions'
import * as s from '../selectors'
import * as t from '../types'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'
import config from 'config'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  data: string
  options: string[]
  setSorting: (attribute: string) => a.SetSorting
}

type DP = {
  setSorting: typeof a.setSorting
}

const conf: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useSorting',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    data: s.getCurrentSorting(state, props.identifier),
    options: Object.keys(config.sortingAttributes).map((key) => config.sortingAttributes[key]),
  }),
  mapDispatch: {
    setSorting: a.setSorting,
  },
  transformDispatch: {
    setSorting: (fn, sp, props) => (attribute) => fn(props.identifier, attribute),
  },
}

export default function useSorting(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect(input, conf)
  return hook
}

export const INIT_LIST: 'products/INIT_LIST' = 'products/INIT_LIST'
export const FETCH_REQUEST: 'products/FETCH_REQUEST' = 'products/FETCH_REQUEST'
export const FETCH_SUCCESS: 'products/FETCH_SUCCESS' = 'products/FETCH_SUCCESS'
export const FETCH_FAILURE: 'products/FETCH_FAILURE' = 'products/FETCH_FAILURE'
export const SET_QUERY: 'products/SET_QUERY' = 'products/SET_QUERY'
export const TOGGLE_CATEGORY: 'products/TOGGLE_CATEGORY' = 'products/TOGGLE_CATEGORY'
export const SET_PAGE: 'products/SET_PAGE' = 'products/SET_PAGE'
export const TOGGLE_FILTER_OPTION: 'products/TOGGLE_FILTER_OPTION' = 'products/TOGGLE_FILTER_OPTION'
export const SET_CONTEXT: 'products/SET_CONTEXT' = 'products/SET_CONTEXT'
export const SET_ANALYTIC_TAGS: 'products/SET_ANALYTIC_TAGS' = 'products/SET_ANALYTIC_TAGS'
export const SET_PRICE_RANGE: 'products/SET_PRICE_RANGE' = 'products/SET_PRICE_RANGE'
export const SET_INDEX: 'products/SET_INDEX' = 'products/SET_INDEX'
export const TOGGLE_TAG: 'products/TOGGLE_TAG' = 'products/TOGGLE_TAG'
export const CLEAR_FILTER_VALUES: 'products/CLEAR_FILTER_VALUES' = 'products/CLEAR_FILTER_VALUES'
export const SET_SORTING: 'products/SET_SORTING' = 'products/SET_SORTING'

import { addRule } from 'redux-ruleset'
import * as api from './utils/api'
import * as at from './const'

// initial send location
// @see https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
if (typeof window !== 'undefined') {
  api.pushToAnalytics({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })
}

addRule({
  id: 'tracking/SEND_EVENT',
  target: at.SEND_EVENT,
  output: '#tracking-event',
  consequence: (action) => {
    api.pushToAnalytics(action.payload)
  },
})

addRule({
  id: 'tracking/SEND_BUFFERED_EVENT',
  target: at.SEND_BUFFERED_EVENT,
  output: '#tracking-event',
  consequence: (action) => {
    api.bufferPushToAnalytics(action.payload, action.meta.context)
  },
})

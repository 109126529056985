// @flow
import { dispatchEvent } from 'redux-ruleset'
import { Props } from './types'

export const CLICK: 'Banner/CLICK' = 'Banner/CLICK'
export const SCROLL_INTO_VIEW: 'Banner/SCROLL_INTO_VIEW' = 'Banner/SCROLL_INTO_VIEW'

export type Click = {
  type: typeof CLICK
  meta: { name: 'Banner' }
  payload: Props
}

export type ScrollIntoView = {
  type: typeof SCROLL_INTO_VIEW
  meta: { name: 'Banner' }
  payload: Props
}

export const click = (props: Props) =>
  dispatchEvent({
    type: CLICK,
    meta: { name: 'Banner' },
    payload: props,
  })

export const scrollIntoView = (props: Props) =>
  dispatchEvent({
    type: SCROLL_INTO_VIEW,
    meta: { name: 'Banner' },
    payload: props,
  })

declare global {
  interface RulesetDispatchEvents {
    'organisms/Banner': Click | ScrollIntoView
  }
}

import * as React from 'react'
import * as t from '../types'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import store from 'store'
import { fetchFilterOptions } from '../utils/api'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
  filterKey: t.FilterKey
}

export type Output = {
  data: t.Filter
  query: string
  exhaustive: boolean
  resetValue: t.FilterValue[]
  setQuery: (query: string) => void // custom prop
  toggleFilterOption: RemoveArg0<RemoveArg0<typeof a.toggleFilterOption>>
  clear: RemoveArg0<typeof a.clearFilterValues>
}

type DP = {
  toggleFilterOption: typeof a.toggleFilterOption
  clear: typeof a.clearFilterValues
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useFilter',
  createCacheKey: (props) => props.identifier + props.filterKey,
  mapState: (state, props) => ({
    data: s.getFilter(state, props.identifier, props.filterKey),
    resetValue: s.getResetFilterValues(state, props.identifier)[props.filterKey],
    exhaustive: s.filtersAreExhaustive(state, props.identifier),
  }),
  mapDispatch: {
    toggleFilterOption: a.toggleFilterOption,
    clear: a.clearFilterValues,
  },
  transformDispatch: {
    toggleFilterOption: (fn, sp, props) => (opt) => fn(props.identifier, sp.data.key, opt),
    clear: (fn, sp, props) => () => fn(props.identifier, props.filterKey),
  },
}

export default function useFilter(identifier: string, filterKey: t.FilterKey): Output {
  const input: Input = { identifier, filterKey }
  const hook: Output = useConnect(input, config)
  const [query, _setQuery] = React.useState('')
  const [queryOptions, setQueryOptions] = React.useState(null)
  const newOptions = queryOptions ? queryOptions : hook.data.options
  const currentQuery = React.useRef('')

  const setQuery = React.useCallback(
    (query) => {
      _setQuery(query)
      currentQuery.current = query
      if (!query) setQueryOptions(null)
      else {
        const state = store.getState()
        const filterValues = s.getFilterValues(state.products, identifier)
        const mode = s.getMode(state.products, identifier)
        fetchFilterOptions(filterValues, filterKey, mode, query).then((options) => {
          if (query === currentQuery.current) setQueryOptions(options)
        })
      }
    },
    [identifier, filterKey]
  )

  return {
    ...hook,
    query,
    setQuery,
    data: { ...hook.data, options: newOptions || [] },
  }
}

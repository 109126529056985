import * as t from './types'
import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import { ms } from 'modules/browser/const'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function Button(props: t.Props) {
  let linkProps = {
    to: props.link,
  }
  if (props.isCPC) {
    Object.assign(linkProps, {
      'data-manualcpc': props.manualcpc,
      'data-e1': props.e1,
      'data-e2': props.e2,
      'data-e3': props.e3,
    })
  }

  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      data-cy-ctx="organisms/Button"
      className="Button"
      stretch={props.stretch}
      ref={wrapperRef}
      onClick={handleClick}
    >
      <Link {...linkProps}>{props.label}</Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.58;

  a {
    padding: 5px 10px;
    color: white;
    font-size: 16px;
    text-align: center;
    background: #555;
    &:hover {
      color: white;
    }
    &:focus {
      color: white;
    }
    @media (min-width: ${ms.LAPTOP}px) {
      font-size: 18px;
    }
  }

  ${(props) =>
    props.stretch &&
    `
    > a {
      display: inline-block;
      width: 100%;
    }
  `}
`

// @flow
import { addRule } from 'redux-ruleset'
import * as ProductModal from 'theme/_core/ProductModal/actions'
import config from 'config'

const { merchantClickout } = config.apiEndpoints

addRule({
  id: 'clickout/PRODUCT_MODAL',
  target: ProductModal.CLICKOUT,
  output: '#clickout',
  consequence: (action) => {
    const { objectID } = action.payload
    window.open(merchantClickout + '/' + objectID + `?branch=${typeof window !== 'undefined' && window.abbranch || 'master'}`, '_blank')
  },
})

import { addRule } from 'redux-ruleset'
import * as m from 'modules/products'

addRule({
  id: 'feature/ADD_QUERY_STRING_TO_URL',
  target: m.FETCH_SUCCESS,
  output: '#url-hash',
  condition: (action) => {
    if (typeof window === 'undefined') return false
    // @ts-ignore
    if (window.document.isStorybook) return false
    return {
      'fashion-product-list': true,
      'generic-product-list': true,
    }[action.meta.identifier] as boolean
  },
  consequence: (action) => {
    const filterValues = action.meta.filterValues
    const hash = m.utils.serialize(filterValues, {
      skipCategory: !window.location.pathname.includes('/search/'),
    })
    if (window.location.hash === hash) return

    if (!hash) {
      if (window.location.hash) window.history.replaceState(null, '', window.location.pathname)
      return
    }
    window.history.replaceState(null, '', window.location.pathname + hash)
  },
})

addRule({
  id: 'feature/HYDRATE_FROM_URL',
  target: m.INIT_LIST,
  output: m.INIT_LIST,
  position: 'INSTEAD',
  weight: 4,
  condition: () => {
    if (typeof window === 'undefined') return false
    // @ts-ignore
    if (window.document.isStorybook) return false
    return window.location.hash.includes('#q=')
  },
  consequence: (action) => {
    const { hash } = window.location
    const filterValues = m.utils.deserialize(hash)

    if (action.meta.identifier === 'fashion-product-list') {
      filterValues.category = action.payload.category
    }

    // add dynamic filters to list when they do not exist in the original dynamicFilters
    const dynamicFilters = action.meta.dynamicFilters
    const hashTagGroups = decodeURIComponent(hash).match(/tagGroups\.[^\]]*/g)

    if(hashTagGroups) {
      for(const path of hashTagGroups) {
        const label = path.split('.')[1]
        const labelExists = dynamicFilters.find(f => f.path === path)
        if(!labelExists) dynamicFilters.push({ label, path })
      }
    }

    return m.init(
      action.meta.identifier,
      filterValues,
      action.meta.resetValues,
      dynamicFilters
    )
  },
})

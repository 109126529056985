// @flow
import * as t from '../types'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  data: string[]
  dict: ReturnType<typeof s.getTagDict>
  toggleTag: (tag: string) => a.ToggleTag
}

type DP = {
  toggleTag: typeof a.toggleTag
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useTags',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    data: s.getTags(state, props.identifier),
    dict: s.getTagDict(state, props.identifier),
  }),
  mapDispatch: {
    toggleTag: a.toggleTag,
  },
  transformDispatch: {
    toggleTag: (fn, sp, props) => (tag: string) => fn(props.identifier, tag),
  },
}

export default function useTags(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect(input, config)
  return hook
}

import { addRule, skipRule } from 'redux-ruleset'
import { getFilterValues } from 'modules/products/selectors'

/**
 * Given the user navigates fom a listing page to a pdp
 * And navigates back to the same listing page
 * Then we hydrate the previous set filter
 */
addRule({
  id: 'feature/PESIST_LISTING_FILTER_AFTER_PDP_VISIT',
  target: 'products/INIT_LIST',
  output: 'products/INIT_LIST',
  position: 'INSTEAD',
  weight: 3,
  addWhen: function*(next, { context, getState }) {
    // wait until a filterValue was set
    const identifier = yield next('products/FETCH_SUCCESS', (action) => action.meta.identifier)
    const listingPathname = window.location.pathname

    const status = yield next('navigation/LOCATION_CHANGE', (action) => {
      const isPDP = action.payload.pathname.includes('/product/')
      if (isPDP) {
        const state = getState()
        const filterValues = getFilterValues(state.products, identifier)
        context.set('filterValues', filterValues)
      }
      return isPDP ? 'IS_PDP' : 'IS_NOT_PDP'
    })

    if (status === 'IS_NOT_PDP') return 'REAPPLY_ADD_WHEN'

    return yield next('navigation/LOCATION_CHANGE', (action) => {
      if (action.payload.pathname.includes('/product/')) return false
      if (action.payload.pathname === listingPathname) return 'ADD_RULE'
      return 'REAPPLY_ADD_WHEN'
    })
  },
  addUntil: function*(next) {
    yield next('products/INIT_LIST')
    return 'RECREATE_RULE'
  },
  consequence: (action, { context }) => {
    const filterValues = context.get('filterValues')
    return skipRule('feature/HYDRATE_FROM_URL', { ...action, payload: filterValues })
  },
})

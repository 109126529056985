// @flow
import * as React from 'react'
import store from 'store'

import 'features'
import 'modules/tracking'
import 'modules/navigation'

type Props = {
  children: any
  partialStateUpdates?: Record<string, unknown>[] | false | null
}

export default function Redux({ children, partialStateUpdates }: Props) {
  React.useMemo(() => {
    partialStateUpdates &&
      partialStateUpdates.length &&
      store.dispatch({
        type: 'PARTIAL_STATE_UPDATES',
        payload: partialStateUpdates,
      })
  }, [partialStateUpdates])
  return children
}

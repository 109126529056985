import * as at from './const'
import { Action } from './actions'

// holds list of product groupedIds
export type State = string[]

export const defaultState: State = (() => {
  if (typeof window === 'undefined') return []
  return window.localStorage.getItem('last-seen-products')?.split(',') || []
})()

export default function reducer(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case at.ADD_ID:
      return Array.from(new Set([...action.payload, ...state])).slice(0, 20)
    default:
      return state
  }
}

import { dispatchEvent } from 'redux-ruleset'
import { Props } from './types'

export const CLICK: 'Button/CLICK' = 'Button/CLICK'
export const SCROLL_INTO_VIEW: 'Button/SCROLL_INTO_VIEW' = 'Button/SCROLL_INTO_VIEW'

export const click = (props: Props) =>
  dispatchEvent({
    type: CLICK,
    meta: { name: 'Button' },
    payload: props,
  })

export const scrollIntoView = (props: Props) =>
  dispatchEvent({
    type: SCROLL_INTO_VIEW,
    meta: { name: 'Button' },
    payload: props,
  })

export type Click = ReturnType<typeof click>
export type ScrollIntoView = ReturnType<typeof scrollIntoView>

declare global {
  interface RulesetDispatchEvents {
    'organisms/Button': Click | ScrollIntoView
  }
}

// @flow
import * as t from '../types'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  data: [number, number] | null
  range: [number, number]
  setPrice: (price: [number, number] | null) => a.SetPrice
}

type DP = {
  setPrice: typeof a.setPrice
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/usePrice',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    data: s.getPrice(state, props.identifier),
    range: s.getPriceRange(state, props.identifier),
  }),
  mapDispatch: {
    setPrice: a.setPrice,
  },
  transformDispatch: {
    setPrice: (fn, sp, props) => (price) => fn(props.identifier, price),
  },
}

export default function usePrice(identifier: string): Output {
  const input: Input = { identifier }
  const hook = useConnect(input, config)
  return hook
}

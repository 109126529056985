// @flow
import './rules'
import store from 'store'
import reducer, { State } from './reducer'
import { Action } from './actions'
import * as utils from './utils/filter-values-utils'

store.injectReducer(store, 'products', reducer)

declare global {
  interface RootState {
    products: State
  }
  interface RootReducers {
    products: typeof reducer
  }
  interface ModuleActions {
    products: Action
  }
}

export * from './actions'
export * from './selectors'
export * from './types'
export * from './const'

export { utils }

export { default as useInitializer } from './hooks/useInitializer'
export { default as useProductList } from './hooks/useProductList'
export { default as useQuery } from './hooks/useQuery'
export { default as useCategories } from './hooks/useCategories'
export { default as usePage } from './hooks/usePage'
export { default as useFilter } from './hooks/useFilter'
export { default as useFilterValues } from './hooks/useFilterValues'
export { default as usePrice } from './hooks/usePrice'
export { default as useIndex } from './hooks/useIndex'
export { default as useTags } from './hooks/useTags'
export { default as useDynamicFilterDefinitions } from './hooks/useDynamicFilterDefinitions'
export { default as useSorting } from './hooks/useSorting'

export default reducer

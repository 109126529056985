import { addRule } from "redux-ruleset"


addRule({
  id: 'feature/showNewslettlerPopup',
  target: 'ProductWidget/CLICK',
  output: '#open-modal',
  addOnce: true,
  addWhen: function* (next) {
    // Trigger after 5th clickout
    yield next('ProductWidget/CLICK')
    return 'ADD_RULE'
  },
  consequence: () => {
    const formId = 'wLX9V1'
    const popupOptions = {
      "layout": 'modal', // Open as a centered modal
      // "width": 680,
      "emoji": {
        "text": "👋",
        "animation": "wave"
      },
      "showOnce": true,
      "doNotShowAfterSubmit": true
    }
    console.log(`Open Tally popup ${formId} w/ options ${JSON.stringify(popupOptions)}…`)
    // requires the Tally widget script being included in the <head> section
    // e.g. via Netlify Post processing Snippet injection 
    // <script src="https://tally.so/widgets/embed.js"></script>
    if (window && (window as any).Tally) {
      (window as any).Tally.openPopup(formId, popupOptions)
    }
  }
})
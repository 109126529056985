import { addRule } from 'redux-ruleset'
import * as actions from './actions'
import * as at from './const'
// import * as a from './actions'
import * as s from './selectors'
import * as api from './utils/api'
import * as filterValuesUtils from './utils/filter-values-utils'
import config from 'config'

addRule({
  id: 'products/TRIGGER_SEARCH',
  weight: 10,
  target: [
    at.INIT_LIST,
    at.SET_PAGE,
    at.SET_PRICE_RANGE,
    at.SET_QUERY,
    at.TOGGLE_CATEGORY,
    at.TOGGLE_FILTER_OPTION,
    at.TOGGLE_TAG,
    at.SET_INDEX,
    at.SET_SORTING,
    // at.CLEAR_TAGS,
    at.CLEAR_FILTER_VALUES,
  ],
  output: at.FETCH_REQUEST,
  consequence: (action, { getState }) => {
    const state = getState()
    const filterValues = s.getFilterValues(state.products, action.meta.identifier)
    const dynamicFilters = s.getDynamicFiltersDefinitions(state.products, action.meta.identifier)
    const mode = s.getMode(state.products, action.meta.identifier)
    return actions.fetchRequest(action.meta.identifier, filterValues, dynamicFilters, mode)
  },
})

addRule({
  id: 'products/FETCH',
  target: at.FETCH_REQUEST,
  output: [at.FETCH_SUCCESS, at.FETCH_FAILURE],
  concurrency: 'SWITCH',
  concurrencyFilter: (action) => action.meta.identifier,
  consequence: (action) => {
    const { identifier, filterValues, dynamicFilters, mode } = action.meta

    return api.fetch(filterValues, dynamicFilters, mode).then(
      (result) => actions.fetchSuccess(identifier, filterValues, dynamicFilters, mode, result),
      (error) =>
        actions.fetchFailure(identifier, filterValues, dynamicFilters, mode, error.toString())
    )
  },
})

addRule({
  id: 'products/PREVENT_INITIALIZATION',
  target: at.INIT_LIST,
  position: 'INSTEAD',
  output: '#cancel',
  weight: 10,
  condition: (action, { getState }) => {
    const state = getState()
    const { identifier } = action.meta
    if (!state.products[identifier]) return false
    const filterValues = s.getFilterValues(state.products, action.meta.identifier)
    const dynamicFilters = s.getDynamicFiltersDefinitions(state.products, action.meta.identifier)
    const dynamicFiltersAreEqual = (df1: { path: string }[], df2: { path: string }[]) => {
      if (df1.length !== df2.length) return false
      const dict: Record<string, string> = {}
      for (const row of df1) dict[row.path] = row.path
      for (const row of df2) if (!dict[row.path]) return false
      return true
    }
    return (
      filterValuesUtils.areFilterValuesEqual(action.payload, filterValues) &&
      dynamicFiltersAreEqual(action.meta.dynamicFilters || [], dynamicFilters)
    )
  },
  consequence: () => undefined,
})

addRule({
  id: 'products/ADD_CONTEXT_AFTER_MOUNT',
  target: at.INIT_LIST,
  output: at.INIT_LIST,
  position: 'INSTEAD',
  weight: 5,
  condition: (action) => !action.payload.context,
  consequence: (action) => {
    const id = Object.keys(config.categories).find(
      (key) => config.categories[key].categoryPath === action.payload.category
    )
    if (id) {
      const context = 'category_' + id
      const values = { ...action.payload, context }
      const resetValues = { ...action.meta.resetValues, context }
      return actions.init(action.meta.identifier, values, resetValues, action.meta.dynamicFilters)
    } else return action
  },
})

addRule({
  id: 'products/ADD_CONTEXT_AFTER_HIERARCHICAL_REFINEMENT',
  target: at.TOGGLE_CATEGORY,
  output: at.SET_CONTEXT,
  consequence: (action) => {
    const id = Object.keys(config.categories).find(
      (key) => config.categories[key].categoryPath === action.payload
    )
    if (id) return actions.setContext(action.meta.identifier, 'category_' + id)
    else return null
  },
})

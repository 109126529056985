// @flow
import * as t from '../types'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  options: t.CategoryOption[]
  category: string
  toggleCategory: (category?: t.CategoryOption) => a.ToggleCategory
}

type DP = {
  toggleCategory: typeof a.toggleCategory
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useCategories',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    options: s.getCategoryOptions(state, props.identifier),
    category: s.getCategory(state, props.identifier),
  }),
  mapDispatch: {
    toggleCategory: a.toggleCategory,
  },
  transformDispatch: {
    toggleCategory: (fn, sp, props) => (category) => fn(props.identifier, category),
  },
}

export default function useCategories(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect<Input, Output, State, DP>(input, config)
  return hook
}

// @flow
import * as React from 'react'
import Markdown from 'theme/atoms/Markdown'

type Props = {
  children: string
  centered: boolean
  bordered: boolean
  verticalCentered: boolean
}

export default function Text({ children, centered, bordered, verticalCentered }: Props) {
  return (
    <Markdown
      className="Text"
      centered={centered}
      bordered={bordered}
      verticalCentered={verticalCentered}
      // eslint-disable-next-line react/no-children-prop
      children={children}
    />
  )
}

// @flow
import * as React from 'react'
import * as t from '../types'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
  filters: Partial<t.FilterValues>
  noFetch?: boolean
}

export type Output = {
  create: (
    filters?: Partial<t.FilterValues>,
    dynamicFilters?: t.DynamicFilterDefinition[]
  ) => a.Init
}

type DP = {
  create: typeof a.init
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useInitializer',
  createCacheKey: (props) => props.identifier,
  mapState: () => ({}),
  mapDispatch: {
    create: a.init,
  },
  transformDispatch: {
    create: (fn, sp, props) => (filters, dynamicFilters) =>
      fn(props.identifier, filters, null, dynamicFilters),
  },
}

export default function useInitializer(
  identifier: string,
  filters: Partial<t.FilterValues>,
  opt?: {
    noFetch?: boolean
    dynamicFilters?: t.DynamicFilterDefinition[]
  }
): Output {
  const input: Input = { identifier, filters, noFetch: opt?.noFetch }
  const hook: Output = useConnect(input, config)
  const fetched = React.useRef(false)

  React.useEffect(() => {
    if (opt?.noFetch) return
    if (fetched.current) return
    fetched.current = true
    hook.create(filters, opt?.dynamicFilters)
  })

  return hook
}

import * as at from './const'

export const addId = (id: string | string[]) => ({
  type: at.ADD_ID,
  payload: typeof id === 'string' ? [id] : id,
})

export type Add = ReturnType<typeof addId>

export type Action = Add

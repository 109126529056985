// @flow
import * as t from '../types'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  data: string
  options: string[]
  setIndex: (index: string) => a.SetIndex
}

type DP = {
  setIndex: typeof a.setIndex
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'products',
  name: 'products/useIndex',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    data: s.getIndex(state, props.identifier),
    options: s.getIndexOptions(state, props.identifier),
  }),
  mapDispatch: {
    setIndex: a.setIndex,
  },
  transformDispatch: {
    setIndex: (fn, sp, props) => (index) => fn(props.identifier, index),
  },
}

export default function usePage(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect(input, config)
  return hook
}

import { addRule, dispatchEvent } from 'redux-ruleset'

// modules
import * as wishlist from 'modules/wishlist'
import * as tracking from 'modules/tracking'
import * as navigation from 'modules/navigation'
// import * as newsletter from 'modules/newsletter'

// widgets
import * as ProductWidget from 'theme/molecules/ProductWidget/actions'
import * as Banner from 'theme/organisms/Banner/actions'
import * as Image from 'theme/organisms/Image/actions'
import * as ImageWithLinks from 'theme/organisms/ImageWithLinks/actions'
import * as SimpleQuestion from 'theme/organisms/SimpleQuestion/actions'
import * as CurratedSearchWidget from 'theme/organisms/CurratedSearchWidget/actions'
import * as CategoryBanner from 'theme/organisms/CategoryBanner/actions'
import * as CategoryBannerRounded from 'theme/organisms/CategoryBannerRounded/actions'
import * as CategoryBannerBorder from 'theme/organisms/CategoryBannerBorder/actions'
import * as CategoryBannerBorderHorizontal from 'theme/organisms/CategoryBannerBorderHorizontal/actions'
import * as Figurtypen from 'theme/organisms/Figurtypen/actions'
import * as BannerText from 'theme/organisms/BannerText/actions'
import * as BannerBrand from 'theme/organisms/BannerBrand/actions'
import * as Button from 'theme/organisms/Button/actions'
import * as CategoryTeaserList from 'theme/organisms/CategoryTeaserList/actions'
import * as FullwidthHeroBanner from 'theme/organisms/FullwidthHeroBanner/actions'
import * as Newsletter from 'theme/organisms/Newsletter/actions'
import * as Sizebar from 'theme/organisms/Sizebar/actions'
import * as Squarelovin from 'theme/organisms/Squarelovin/actions'
import * as Suggestion from 'theme/organisms/Suggestion/actions'
import * as Video from 'theme/organisms/Video/actions'

const pushToAnalytics = (event: any) => dispatchEvent(tracking.sendEvent(event))
const bufferPushToAnalytics = (event: any, context: tracking.EventContext) =>
  dispatchEvent(tracking.sendBufferedEvent(event, context))


// addRule({
//   id: 'ecommercetracking/ADD_LISTING_ITEM_TO_WISHLIST',
//   target: ProductWidget.HEART_CLICK,
//   output: tracking.SEND_EVENT,
//   consequence: (action, { effect }) => {
//     effect(() =>
//       pushToAnalytics({
//         event: 'add_to_wishlist',
//         ecommerce: {
//           items: [
//             {
//               item_id: action.payload.objectID,
//               item_name: action.payload.productName,
//               item_brand: action.payload.merchantName,
//               item_variant: action.payload.productColor,
//               price: action.payload.productPrice,
//               currency: 'EUR',
//               quantity: '1',
//             },
//           ],
//         },
//       })
//     )
//   },
// })

addRule({
  id: 'ecommercetracking/VIEW_ITEM_LIST',
  target: ProductWidget.SCROLL_INTO_VIEW,
  output: tracking.SEND_BUFFERED_EVENT,
  addUntil: function*(next, { context }) {
    yield next(ProductWidget.SCROLL_INTO_VIEW, (action) => {
      const seenProducts = context.get('products-seen') || 0

      if (action.meta.listName !== context.get('last-list-name')) {
        context.set('products-seen', 0)
        context.set('last-list-name', action.meta.listName)
      }

      context.set('products-seen', seenProducts + 1)
    })
    return 'RECREATE_RULE'
  },
  // we are only interested in the very first event per unique list.
  condition: (_, { context }) => context.get('products-seen') === 1,
  consequence: (action, { effect }) => {
    const event = {
      event: 'view_item_list',
      ecommerce: [
        {
          list_name: action.meta.listName,
        },
      ],
    }
    const context = {
      delay: 5000,
      itemKey: ['ecommerce'],
      bufferId: 'tracking/VIEW_ITEM_LIST',
    }
    effect(() => bufferPushToAnalytics(event, context))
  },
})

addRule({
  id: 'ecommercetracking/VIEW_ITEM',
  target: ProductWidget.SCROLL_INTO_VIEW,
  output: tracking.SEND_BUFFERED_EVENT,
  consequence: (action, { effect }) => {
    const event = {
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: action.payload.objectID,
            item_name: action.payload.productName,
            item_brand: action.payload.merchantName,
            item_variant: action.payload.productColor,
            item_list_name: action.meta.listName,
            index: action.meta.index + 1,
            price: action.payload.productPrice,
            currency: 'EUR',
            dimension14: action.payload._tags?.includes('directbuy') ? 'direct' : 'clickout',
            dimension15: action.payload.merchantName,
          },
        ],
      },
    }

    const context = {
      delay: 5000,
      itemKey: ['ecommerce', 'items'],
      bufferId: 'tracking/VIEW_ITEM',
    }
    effect(() => bufferPushToAnalytics(event, context))
  },
})

addRule({
  id: 'ecommercetracking/SELECT_ITEM',
  target: ProductWidget.CLICK,
  output: tracking.SEND_EVENT,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_id: action.payload.groupedId,
              item_name: action.payload.productName,
              item_brand: action.payload.merchantName,
              item_variant: action.payload.productColor,
              price: action.payload.productPrice,
              item_list_name: action.meta.listName,
              index: action.meta.index + 1,
              currency: 'EUR',
              dimension14: action.payload._tags?.includes('directbuy') ? 'direct' : 'clickout',
              dimension15: action.payload.merchantName,
            },
          ],
        },
      })
    )
  },
})

addRule({
  id: 'ecommercetracking/VIEW_PROMOTION',
  target: [
    Banner.SCROLL_INTO_VIEW,
    SimpleQuestion.SCROLL_INTO_VIEW,
    CurratedSearchWidget.SCROLL_INTO_VIEW,
    Image.SCROLL_INTO_VIEW,
    ImageWithLinks.SCROLL_INTO_VIEW,
    CategoryBanner.SCROLL_INTO_VIEW,
    CategoryBannerBorder.SCROLL_INTO_VIEW,
    CategoryBannerBorderHorizontal.SCROLL_INTO_VIEW,
    CategoryBannerRounded.SCROLL_INTO_VIEW,
    Figurtypen.SCROLL_INTO_VIEW,
    BannerText.SCROLL_INTO_VIEW,
    BannerBrand.SCROLL_INTO_VIEW,
    Button.SCROLL_INTO_VIEW,
    CategoryTeaserList.SCROLL_INTO_VIEW,
    FullwidthHeroBanner.SCROLL_INTO_VIEW,
    Newsletter.SCROLL_INTO_VIEW,
    Sizebar.SCROLL_INTO_VIEW,
    Squarelovin.SCROLL_INTO_VIEW,
    Suggestion.SCROLL_INTO_VIEW,
    Video.SCROLL_INTO_VIEW,
  ],
  output: tracking.SEND_BUFFERED_EVENT,
  consequence: (action, { effect }) => {
    const event = {
      event: 'view_promotion',
      ecommerce: [
        {
          promotion_name: action.payload.gridArea,
        },
      ],
    }
    const context = {
      delay: 5000,
      itemKey: ['ecommerce'],
      bufferId: 'tracking/VIEW_PROMOTION',
    }
    effect(() => bufferPushToAnalytics(event, context))
  },
})

addRule({
  id: 'ecommercetracking/SELECT_PROMOTION',
  target: [
    Banner.CLICK,
    SimpleQuestion.CLICK,
    CurratedSearchWidget.CLICK,
    Image.CLICK,
    ImageWithLinks.CLICK,
    CategoryBanner.CLICK,
    CategoryBannerBorder.CLICK,
    CategoryBannerBorderHorizontal.CLICK,
    CategoryBannerRounded.CLICK,
    Figurtypen.CLICK,
    BannerText.CLICK,
    BannerBrand.CLICK,
    Button.CLICK,
    CategoryTeaserList.CLICK,
    FullwidthHeroBanner.CLICK,
    Newsletter.CLICK,
    Sizebar.CLICK,
    Squarelovin.CLICK,
    Suggestion.CLICK,
    Video.CLICK,
  ],
  output: tracking.SEND_EVENT,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'select_promotion',
        promotion_name: action.payload.gridArea,
      })
    )
  },
})

addRule({
  id: 'ecommercetracking/GENERATE_LEAD',
  target: 'Footer/SUBSCRIBE' /* new Newslettermodule newsletter.PUSH_SUBSCRIPTION */,
  output: tracking.SEND_EVENT,
  consequence: (action, { effect }) => {
    effect(() =>
      pushToAnalytics({
        event: 'generate_lead',
        promotion_name: action.type,
      })
    )
  },
})

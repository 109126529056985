// @flow
import * as t from '../types'
import * as s from '../selectors'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  identifier: t.Identifier
}

export type Output = {
  data: t.Product[] | null
  isFetching: boolean
  fetchError: null | string
  numHits: number
  queryID: string
}

const config: Config<Input, Output, State, never> = {
  moduleKey: 'products',
  name: 'products/useProductList',
  createCacheKey: (props) => props.identifier,
  mapState: (state, props) => ({
    ...s.getRequest(state, props.identifier),
    queryID: s.getQueryID(state, props.identifier),
    numHits: s.getNumHits(state, props.identifier),
  }),
}

export default function useProductList(identifier: string): Output {
  const input: Input = { identifier }
  const hook: Output = useConnect(input, config)
  return hook
}
